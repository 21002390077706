import HeaderAppyShop, {getComponentStaticProps, HeaderProps} from '../../components/header/headerAppyShop'
import FooterAppyShop from '../../components/footer/footerAppyShop'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import {config} from '../../config-merchant'
import {useTranslation} from 'next-i18next';
import Meta from '../../components/meta/meta';
import { appyShopConfig } from '../../config';
import styles from '../../styles/client-FAC.custom.module.scss';
import Products from "../../components/catalog/items-list";
import Link from "next/link";

export default function Home(props: { headerProps: HeaderProps }) {

    const {i18n} = useTranslation('common');

    return (
        <>
            <Meta
                title={""}
                description={config.shopSlogan[i18n.language]}
                image={config.merchantDomain + config.defaultMetaImage}
                metaRobots={true}
            />
            <HeaderAppyShop activepage={'home'} headerProps={props.headerProps}/>

            <main>

                <div className={`${styles['home']} ${styles['custom']}`}>

                    <div className={styles["cover"]}>
                        <img src="/assets/images/FAC/home-cover-1.jpg" alt="cover"/>
                    </div>

                    <div className={styles["home-text1"]}>
                        <img src="/assets/images/FAC/illustration-violette.png" alt="violette"/>
                        <h2>Bienvenue ! </h2>
                        <p>Notre boutique de <b>fleurs cristallisées en ligne</b> vous propose des idées gourmandes et
                            originales pour agrémenter vos desserts.</p>
                        <p>Exploitation familiale, producteur de violette à <b>Tourrettes-sur-Loup</b>, nous élaborons
                            des produits raffinés à base de <b>violette</b>, de <b>rose</b>, de <b>menthe</b> et
                            de <b>verveine</b>.</p>
                    </div>

                    <div className={styles["home-imgs"]}>
                        <div>
                            <img src="/assets/images/FAC/home-img-1.jpg" alt={"Nos fleurs cristallisées"}/>
                            <div>
                                <p>Nos fleurs cristallisées : Violette, rose, menthe, verveine !</p>
                                <Link href={"/catalog?category=4"}>
                                    <a>
                                        <div className={styles["button"]}>Tout voir</div>
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <img src="/assets/images/FAC/home-img-2.jpg" alt={"Nos violettes fraîches "}/>
                            <div>
                                <p>Nos violettes fraîches !</p>
                                <Link href={"/catalog?category=5"}>
                                    <a>
                                        <div className={styles["button"]}>Tout voir</div>
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <img src="/assets/images/FAC/home-img-3.jpg" alt={"Nos savons maison"}/>
                            <div>
                                <p>Nos savons maison !</p>
                                <Link href={"/catalog?category=8"}>
                                    <a>
                                        <div className={styles["button"]}>Tout voir</div>
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={styles["home-imgs"]}>
                        <div>
                            <img src="/assets/images/FAC/home-img-4.jpg"/>
                            <div>
                                <p>Qui sommes-nous ?</p>
                                <a href={"/about"}>
                                    <div className={styles["button"]}>En savoir plus</div>
                                </a>
                            </div>
                        </div>
                        <div>
                            <img src="/assets/images/FAC/home-img-5.jpg" alt={"Nos sirops"}/>
                            <div>
                                <p>Nos sirops à la violette et la rose !</p>
                                <Link href={"/catalog?category=7"}>
                                    <a>
                                        <div className={styles["button"]}>Tout voir</div>
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div>
                            <img src="/assets/images/FAC/home-img-6.jpg" alt={"contact"}/>
                            <div>
                                <p>Une question ?</p>
                                <Link href={"/contact"}>
                                    <a>
                                        <div className={styles["button"]}>Contactez-nous</div>
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className={styles["home-text2"]}>
                        <p>Tous nos <b>produits artisanaux</b> sont issus de la transformation de fleurs fraîches
                            cultivées en lutte <b>biologique raisonnée</b> au sein de notre exploitation à
                            Tourrettes-sur-Loup (Alpes Maritimes France).</p>
                        <a href={"/catalog"}>
                            <div className={styles["button"]}>Voir tous nos produits</div>
                        </a>
                    </div>

                    <div className={styles["home-text3"]}>
                        <h2>Notre sélection</h2>
                        <div className={styles["home-select-products"]}>
                            <Products
                                nbProducts={4}
                                activeCategory={undefined}
                                activeSorting={"RELEVANCE"}
                                researchQuery={""}
                                setNbProducts={() => {/*ignored*/
                                }}
                                activeTags={[8]}
                            />
                        </div>
                        <a href={"/catalog"}>
                            <div className={styles["button"]}>Notre catalogue complet</div>
                        </a>
                    </div>

                </div>

            </main>


            <FooterAppyShop/>
        </>

    )
}

export const getStaticProps = async ({locale}) => {
    if (config.menu.some(item => item.path === 'home') || config.footerLink.some(item => item.url === '/home')) {
        return {
            props: {
                ...await serverSideTranslations(locale, ['common']),
                headerProps: await getComponentStaticProps(locale)
            },
            revalidate: appyShopConfig.revalidateData
        }
    } else {
        return {
            notFound: true
        }
    }
}



